import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import {
  downloadAppButton,
  hero,
  heroText,
  logo,
  mobileHero,
  mobileHeroText,
  mobileOrderButton,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <Header />

          <CFView
            textCenter
            column
            center
            absolute
            ph="7%"
            pt="5px"
            w="100%"
            maxWidth="1200px"
            pt="10px"
            bg="rgba(0,0,0,.7)"
            bc="#B88F60"
            bw="1.5px"
            style={{
              bottom: '0',
              right: '50%',
              transform: 'translateX(50%)',
            }}
          >
            <CFView column center>
              <CFText color="white" hero raleway>
                NOW TAKING ONLINE ORDERS
              </CFText>
            </CFView>
            <CFView pt="10px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) bottom/ cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
          zIndex={90}
          relative
        >
          <Header />
          <CFView
            row
            justifyAround
            alignCenter
            absolute
            ph="10%"
            w="100%"
            maxWidth="1200px"
            pv="5px"
            bg="rgba(0,0,0,.7)"
            bc="#B88F60"
            bw="1.5px"
            style={{
              bottom: '0',
              right: '50%',
              transform: 'translateX(50%)',
            }}
          >
            <CFView column justifyStart>
              <CFText color="white" hero raleway>
                NOW TAKING ONLINE ORDERS
              </CFText>
            </CFView>
            <CFView pt="10px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
