import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h2 futura bold color="#E4691F" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center white bold>
            <CFLink href="https://www.facebook.com/pages/Sushi%20Aria/1012844792180773/">
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="Sushi Aria Facebook"
              />
            </CFLink>
            <CFLink href="https://www.yelp.ca/biz/sushi-aria-richmond-5">
              <CFImage w="45px" pr="10px" src={yelp} alt="Sushi Aria Yelp" />
            </CFLink>
            <CFLink href="https://www.zomato.com/vancouver/sushi-aria-central-richmond-richmond">
              <CFImage
                w="45px"
                pr="10px"
                src={zomato}
                alt="Sushi Aria Zomato"
              />
            </CFLink>
            <CFLink href="https://www.instagram.com/sushiaria_richmond/?hl=en">
              <CFImage
                w="45px"
                pr="10px"
                src={instagram}
                alt="Sushi Aria Instagram"
              />
            </CFLink>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView h2 futura bold color="#E4691F" mb="15px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter white bold>
            <CFLink href="https://www.facebook.com/pages/Sushi%20Aria/1012844792180773/">
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="Sushi Aria Facebook"
                hover
              />
            </CFLink>
            <CFLink href="https://www.yelp.ca/biz/sushi-aria-richmond-5">
              <CFImage
                w="45px"
                pr="10px"
                src={yelp}
                alt="Sushi Aria Yelp"
                hover
              />
            </CFLink>
            <CFLink href="https://www.zomato.com/vancouver/sushi-aria-central-richmond-richmond">
              <CFImage
                w="45px"
                pr="10px"
                src={zomato}
                alt="Sushi Aria Zomato"
                hover
              />
            </CFLink>
            <CFLink href="https://www.instagram.com/sushiaria_richmond/?hl=en">
              <CFImage
                w="45px"
                pr="10px"
                src={instagram}
                alt="Sushi Aria Instagram"
                hover
              />
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
